<template>
  <div >
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
  <!-- <div v-else>
    <router-view v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
  </div> -->
</template>

<script lang="ts">
import { defineComponent, h, KeepAlive, ref } from 'vue'
import { RouterView, useRoute } from 'vue-router'
export default defineComponent({
  components: {
    RouterView
  },
  setup () {
    const route = useRoute()
    const flag = ref(false)
    if (route.meta.keepAlive) {
      flag.value = true
    } else {
      flag.value = false
    }
    return {
      flag
    }
  }
  // render () {
  //   return h(RouterView, {
  //   }, {
  //     default: ({ Component }: { Component: any }) => h({}, [h(Component)])
  //   })
  // }
})
</script>
