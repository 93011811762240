/*
 * @Descripttion: 
 * @version: 
 * @Author: wenboliu
 * @Date: 2024-04-01 10:22:39
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-04-01 10:22:44
 */
import md5 from 'md5'

/**
 * 请求参数排序
 * @param inObject
 * @returns
 */
function sorter (inObject: any) {
  const sortedJson: any = {}
  const sortedKeys = Object.keys(inObject).sort()
  for (let i = 0; i < sortedKeys.length; i++) {
    sortedJson[sortedKeys[i]] = inObject[sortedKeys[i]]
  }
  return sortedJson
}
/**
 * 请求参数签名
 * @param secretKey
 * @param inObject
 * @returns
 */
export function genSignParams (secretKey: any, inObject: any) {
  inObject.appId = secretKey
  const sortedParam = sorter(inObject)
  let needSignatureStr = ''
  for (const key in sortedParam) {
    let value = sortedParam[key]
    if (value === undefined || value === null || value === '') {
      continue
    }
    if (typeof value === 'object') {
      value = JSON.stringify(value)
    }
    needSignatureStr = needSignatureStr + key + '=' + value + '&'
  }
  needSignatureStr += 'key=abcdefghijklmnopqrstuvwxyz'
  if (process.env.NODE_ENV === 'development') {
  }

  return md5(needSignatureStr).toUpperCase()
}
/**
 * 随机字符串
 * @param e
 * @returns
 */
export function randomString (e: any) {
  e = e || 32
  const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const a = t.length
  let n = ''
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
  return n
}
