/*
 * @Descripttion: 
 * @version: 
 * @Author: wenboliu
 * @Date: 2024-04-01 10:33:47
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-05-21 10:35:41
 */
import { get, post } from './http'

const API: any = {
  getUserInfo: '/permission/user/currentUser', // 获取用户信息
  updateUserInfo: '/design/member/update', // 获取用户信息
  getMenus: '/permission/menu/menus', // 获取所有菜单
  getPermission: '/permission/menu/chlidren', // 获取所有按钮
  getRoles: '/permission/menu/roles',// 获取角色信息
  getDartment: '/user/department/departments', // 获取部门带人员
  getDept: '/user/department/tree', // 获取组织架构部门
  getUserByToken:'/user/user/getUserByToken' ,//获取当前人员的职位
}

export const getUserInfoApi = () => get(API.getUserInfo, '', '')
export const updateUserInfoApi = (data: any) => post(API.updateUserInfo, data, '')

export const getMenusApi = (data: any) => get(API.getMenus, data, '')
export const getPermissionApi = (data: any) => get(API.getPermission, data, '')
export const getRolesApi = (data: any) => get(API.getRoles, data, '')
export const getDartmentApi = (data: any) => get(API.getDartment, data, '')
export const getDeptApi = (data: any) => get(API.getDept, data, '')
export const getUserByTokenApi = (data: any) => get(API.getUserByToken, data, '')
