<!--
 * @Descripttion: 
 * @version: 
 * @Author: wenboliu
 * @Date: 2024-04-01 09:55:26
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-04-28 10:25:30
-->
<template>
  <router-view v-if="isRouter"/>
</template>
<script lang="ts" setup>
import { nextTick, provide, ref } from "vue";
  const isRouter = ref(true);
  const reload = () => {
    isRouter.value = false;
    nextTick(() => {
      isRouter.value = true;
    });
  };
  provide("reload", reload);
</script>


<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}
</style>
