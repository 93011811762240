<!--
 * @Descripttion: 
 * @version: 
 * @Author: wenboliu
 * @Date: 2024-04-02 20:53:49
 * @LastEditors: 毛凯悦
 * @LastEditTime: 2024-04-09 09:54:31
-->
<template>
  <!-- 公共标签栏组件 -->
  <div class="main-breadcrumb">
   
    <el-breadcrumb>
      <el-breadcrumb-item
        v-for="(route, i) in crumbList"
        :key="route.name"
        :to="{ path: '' }"
        :class="{ 'is-last-link': i == crumbList.length - 1, }">
        <span>
          {{ route.meta.title }}
        </span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script lang="ts" setup>
import { computed} from 'vue'
import { userDataStore } from '@/store'
const store = userDataStore()
const crumbList = computed(() => store.getCrumbList)
</script>

<!-- CSS样式 -->
<style scoped lang="less">
.main-breadcrumb {
  height: 40px;
  min-width: 705px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  border-bottom: 1px solid #e5e5e5;
  transition: margin-left 0.3s;
  &.active {
    margin-left: 80px;
  }
  .el-breadcrumb {
    margin-left: 22px;
  }
}
</style>
