<template>
  <!-- 公共侧边栏组件 -->
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="currentMenu"
      :collapse="isCollapse"
      background-color="#fff"
      unique-opened
      router
    >
      <!-- 对items进行遍历 -->
      <template v-for="(item,index) in states.routersList">
        <!-- 使用v-if判定是否具有第二级菜单 -->
          <template v-if="item.children.length!==0 && !item.hideChildrenInMenu">
            <el-sub-menu :index="item.name" :key="index">
              <template #title>
                <!-- <el-icon><component :is="item.meta.icon" /></el-icon> -->
                <i :class="item.meta.icon"></i>
                <!-- 如果存在第二级菜单，渲染第一级菜单标题 -->
                <span>{{ item.meta.title }}</span>
              </template>
              <!-- 遍历第二级菜单 -->
              <template v-for="(subItem,index2) in item.children">
                <!-- 判定是否具有第三级菜单 -->
                <el-sub-menu
                  v-if="subItem.children.length!==0"
                  :index="subItem.name"
                  :key="index2"
                >
                  <!-- 如果存在第三级菜单，渲染第二级菜单标题 -->
                  <template #title>{{ subItem.meta.title }}</template>
                  <!-- 遍历第三级菜单，并渲染第三级菜单标题 -->
                  <el-menu-item
                    v-for="(threeItem,i) in subItem.children"
                    :key="i"
                    :index="threeItem.name"
                    @click="toView(threeItem.path, '')"
                    >{{ threeItem.meta.title }}</el-menu-item
                  >
                </el-sub-menu>
                <!-- 如果不存在第三级菜单，渲染第二级菜单标题 -->
                <el-menu-item v-else-if="!subItem.hidden" :index="subItem.name" :key="subItem.name" @click="toView(subItem.path,subItem.redirect)">{{ subItem.meta.title }}</el-menu-item
                >
              </template>
            </el-sub-menu>
          </template>
        <!-- 如果不存在第二级菜单，渲染第一级菜单标题 -->
        <template v-else>
          <el-menu-item :index="item.name" :key="index"  @click="toView(item.path,item.redirect)">
            <!-- <el-icon><component :is="item.meta.icon" /></el-icon> -->
            <i :class="item.meta.icon"></i>
            <template #title >{{ item.meta.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
    <div @click="switchCollapse" :class="{'open':!isCollapse,'close':isCollapse}" >
      <el-icon >
        <ArrowLeft />
      </el-icon>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { userDataStore } from '@/store'
export default defineComponent({
  setup () {
    const store = userDataStore()
    const isCollapse = computed(() => store.getIsCollapse)

    const currentMenu = computed(() => store.getCurrentMenu)

    const router = useRouter()
    const toView = (url: string, redirect: string) => {
      if (redirect) {
        router.push({ path: redirect })
      } else {
        router.push({ path: url })
      }
    }
    const switchCollapse = () => {
      store.switchCollapse(!isCollapse.value)
    }
    onMounted(() => {
    })
    const state = {
      title: '英奢集团',
      logoUrl: require('@/assets/logo.png')
    }
    const titleImg = ref()
    watch(() => isCollapse, (newVal, oldVal) => {
    })
    const states: any = reactive({
      routersList: computed(() => store.getRouters)
    })

    return {
      state,
      states,
      isCollapse,
      currentMenu,
      titleImg,
      toView,
      switchCollapse
    }
  }
})
</script>

<style lang="less" scoped>
 .el-menu-item.is-active {
    background: rgba(0, 137, 255, 0.08)!important;
    border-left: 3px solid #0089FF!important;
    color: #0089FF;
    i{
      color: #0089FF !important;
    }
  }
  .el-menu-item:hover {
    background-color:rgba(0, 137, 255, 0.08)!important;
    color:#0089FF;
  }
 :deep(.el-sub-menu.is-active .el-sub-menu__title i){
    color:#0089FF!important;
  }
  :deep(.el-sub-menu.is-active .el-sub-menu__title){
    color:#0089FF;
  }
  // .el-sub-menu:hover {
  //   background-color: var(--hover-color)!important;
  //   color:#fff!important
  // }
  :deep(.el-sub-menu .el-sub-menu__title:hover) {
    background-color: rgba(0, 137, 255, 0.08)!important;
    color:#0089FF;
  }
  .sidebar {
    display: flex;
    position: absolute;
    left: 0;
    top: 56px;
    bottom: 0;
    overflow-y: scroll;
  }
  .sidebar::-webkit-scrollbar {
    width: 0;
  }
  .sidebar-el-menu:not(.el-menu--collapse) {
    width: 208px;
  }
  .sidebar > ul {
    height: 100%;
  }
  .sidebar > ul > li {
    margin-bottom: 4px;
  }
  .sidebar-el-menu{
    border-right: 1px solid #f3f3f3;
    z-index: 999;
  }
  .el-menu-item{
    text-align: left;
    color: rgba(0, 0, 0, 0.65);
    i{
      color: #999;
    }
  }
  :deep .el-sub-menu{
    i{
      color: #999;
    }
    .el-sub-menu__title{
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .el-sub-menu{
    text-align: left;
  }
  .open,.close{
    cursor: pointer;
    /* position: fixed; */
    /* width: 14px; */
    height: 74px;
    border-left: 15px solid #0089FF!important;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    left: 208px;
    top: 45%;
    z-index: 999;
    opacity: 1;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 160%;
    margin-left: -1px;
  }
  .close{
    left: 64px;
  }
  .open i{
    margin-left: -17px;
    color: #fff;
  }
  .close i{
    margin-left: -17px;
    color: #fff;
  }
  .el-menu-item, .el-sub-menu__title{
    height: 36px;
    line-height: 36px;
  }
 :deep(.el-sub-menu .el-sub-menu__title){
    height: 36px!important;
    line-height: 36px!important;
  }
 :deep(.el-sub-menu .el-menu-item){
    height: 36px!important;
    line-height: 36px!important;
  }
  :deep(.iconfont){
    margin-right: 10px;
  }
</style>
