<!--
 * @Descripttion: 
 * @version: 
 * @Author: wenboliu
 * @Date: 2024-04-01 18:22:34
 * @LastEditors: 毛凯悦
 * @LastEditTime: 2024-04-05 10:44:45
-->
<template>
   <div class="common-title-container">
    <p  class="common-title">{{ props.title }}</p>
    <slot />
  </div>
</template>
<script setup lang="ts">
  import { defineProps } from 'vue';
  const props = defineProps({
    title: {
      type: String,
      default: ''
    }
  })
</script>
<style lang="less" scoped>
.common-title-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0,0,0,0.9);
}
.common-title{
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: 500;
  height: 15px;
}
.common-title::before{
  content: "";
  display: block;
  width: 3px;
  height: 14px;
  background-color: #0089FF!important;
  margin-right: 5px;
}
</style>