import axios, { AxiosRequestConfig, AxiosResponse,InternalAxiosRequestConfig } from 'axios'
import { ElMessage } from 'element-plus'
import { getCookie } from '../utils/util'
import { genSignParams, randomString } from '../utils/sign'

const statusMap: any = [
  { code: 400, message: '参数有误' },
  { code: 401, message: '未登录' },
  { code: 404, message: '资源不存在' },
  { code: 403, message: '权限不足' },
  { code: 405, message: '请求方法错误' },
  { code: 500, message: '服务器内部错误' },
  { code: 502, message: '网关错误' },
  { code: 504, message: '请求超时' }

]

/* 实例化请求配置 */
const request = axios.create({
  // 请求头POST指定json格式，添加跨域
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
    // "Access-Control-Allow-Origin-Type": "*"
  },
  // 请求时长
  timeout: 1000 * 30,
  // 请求的base地址
  baseURL: process.env.VUE_APP_BASE_API,
  // 表示跨域请求时是否需要使用凭证
  withCredentials: false
})

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
request.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // 开发环境自动添加token
    if (process.env.NODE_ENV === 'development') {
      document.cookie = 'token=' + process.env.VUE_APP_TOKEN
      // 拆分权限请求
      if (config.url && config.url.indexOf('/permission') === 0) {
        config.baseURL = process.env.VUE_APP_API_BASE_PERMISSION
      }
      // 拆分权限请求
      if (config.url && config.url.indexOf('/user') === 0) {
        config.baseURL = process.env.VUE_APP_API_BASE_PERMISSION
      }
    }
    const method = config.method

    if (method === 'get' || method === 'delete') {
      const params = config.params ? config.params : {}
      const timestamp = new Date().getTime()
      const appid = '12345678'
      const nonceStr = randomString(8)
      const sign = genSignParams(appid, {
        ...params,
        url: config.url,
        timestamp: timestamp,
        nonceStr: nonceStr
      })
      config.params = {
        ...params,
        timestamp: timestamp,
        nonceStr: nonceStr,
        sign: sign,
        appId: appid
      }
    } else {
      const params = config.data ? config.data : {}
      const timestamp = new Date().getTime()
      const appid = '12345678'
      const nonceStr = randomString(8)
      const sign = genSignParams(appid, {
        body: JSON.stringify(params),
        url: config.url,
        timestamp: timestamp,
        nonceStr: nonceStr
      })
      config.params = {
        timestamp: timestamp,
        nonceStr: nonceStr,
        sign: sign,
        appId: appid
      }
    }
    const token = getCookie('token')
    if (config.headers && token) {
      config.headers.token = token
    } else {
      window.location.href = process.env.VUE_APP_LOGIN_URL + '?' + window.location.href
    }
    return config
  },
  (error: any) => {
    return Promise.reject(error.data.error.message)
  }
)

/**
 * 请求拦截器
 */
request.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 200) {
      if(response.data.code !== 200){
          if (response.data.code === 401 || response.data.code === 403) {
            ElMessage.error(response.data.message)
            window.localStorage.removeItem('token')
            window.location.href = process.env.VUE_APP_API_BASE_CONSOLE || ''
          } else if (response.data.code !== 200 && response.data.code) {
            ElMessage.error(response.data.message)
          }
          if (response.headers['content-disposition']) {
            return Promise.resolve(response)
          } else {
            return Promise.reject(response)
          }
      }
      
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response)
    }
  },
  (error: any) => {
    if (error.response.status) {
      const errmsg = statusMap.find((item: { code: any }) => {
        return error.response.status === item.code
      })
      errmsg ? ElMessage.error(errmsg.message) : ElMessage.error('网络接口错误')
      return Promise.reject(error.response)
    }
  }
)

export function get (url: string, params: any = {}, type:any) {
  return request({
    url: url,
    method: 'get',
    params: params,
    ...type
  })
}

export function post (url: string, data: any = {}, type:any) {
  return request({
    url: url,
    method: 'post',
    data: data,
    ...type
  })
}
// 封装POST请求方法，使用FormData
export function postFormData (url: string, formData: any) {
  return request({
    url: url,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export default request
