/*
 * @Descripttion:
 * @version:
 * @Author: wenboliu
 * @Date: 2024-03-12 18:02:00
 * @LastEditors: 毛凯悦
 * @LastEditTime: 2024-04-09 09:38:46
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { userDataStore } from '@/store'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页'
    },
    component: () => import(/* webpackChunkName: "index" */ '@/components/nav/index.vue')
  },
  {
    path: '/403',
    meta: {
      title: '无权限'
    },
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403.vue')
  },
  {
    path: '/404',
    meta: {
      title: '未找到'
    },
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404.vue')
  },
  {
    path: '/500',
    meta: {
      title: '服务错误'
    },
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500.vue')
  }]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to: any, from: any, next: any) => {
  // 添加网站标题
  if (to.meta.title && typeof (to.meta.title) === 'string') {
    if (to.path === '/') {
      document.title = '招聘系统'
    } else {
      document.title = to.meta.title + '-英奢集团'
    }
  }
  // 顶部面包屑
  const routerList = to.matched
  // 当前active导航菜单
  if (to.name !== 'payslipManage' && to.name !== 'payslipEdit' && to.name !== 'payslipAdd') {
    sessionStorage.setItem('payslipPwd', '')
  }
  const store = userDataStore()
  store.SET_CRUMBLIST(routerList)
  if (routerList.length >= 2) {
    const parent = routerList[routerList.length - 2].children
    parent.forEach((item:any) => {
      if (item.name === to.name) {
        if (!item.hidden) {
          store.SET_CURRENT_MENU(to.name)
        } else {
          store.SET_CURRENT_MENU(from.name)
        }
      }
    })
  } else {
    store.SET_CURRENT_MENU(to.name)
  }
})

export default router
