/*
 * @Descripttion: 
 * @version: 
 * @Author: wenboliu
 * @Date: 2024-04-01 09:55:26
 * @LastEditors: 毛凯悦
 * @LastEditTime: 2024-06-05 09:43:52
 */
import { createApp,Directive } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import * as ElIcons from '@element-plus/icons'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import './assets/iconfont/iconfont.css'
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
import * as directives from '@/utils/directive'
import comTitle from './components/comTitle/index.vue'
import { createPinia } from 'pinia'
const pinia = createPinia()
// 引入重置样式
import "./style/reset.less";
import "./style/common.less";
import './menu' // 判断登陆人是否有权限
const app = createApp(App)
Object.keys(directives).forEach(key => { // Object.keys() 返回一个数组，值是所有可遍历属性的key名
  app.directive(key, (directives as { [key: string ]: Directive })[key]) // key是自定义指令名字；后面应该是自定义指令的值，值类型是string
})
app.directive('noMoreClick', {
  mounted (el: any, binding: any) {
    el.addEventListener('click', (e: any) => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 2000) // 我这里设置的是2000毫秒也就是2秒
    })
  }
})
for (const name in ElIcons){
	app.component(name,(ElIcons as any)[name])
}
app.component('comTitle', comTitle)
app.use(router).use(ElementPlus, {locale: zhCn}).use(pinia).use(Avue).mount('#app')
