/*
 * @Descripttion:
 * @version:
 * @Author: 毛凯悦
 * @Date: 2024-03-31 13:36:56
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-06-04 10:17:41
 */

import router from './router'
import { userDataStore } from '@/store'
import index from './components/nav/index.vue'
import RouteView from './components/nav/RouteView.vue'
import { ElMessage } from 'element-plus'
import { getMenusApi, getPermissionApi, getRolesApi, getUserInfoApi, getUserByTokenApi } from '@/api/api'
const constantRouterComponents: any = {
  RouteView,
  index
}

const params = {
  businessLineTag: 'HUMAN_RESOURCE',
  projectTag: 'RERECRUIT'
}
router.beforeEach((to:any, from:any, next:any) => {
  const store = userDataStore()
  if (!store.getRouters.length) {
    //  获取菜单和路由
    getMenusApi(params).then((res: any) => {
      if (res.data.length === 0) {
        window.location.href = process.env.VUE_APP_API_BASE_CONSOLE || ''
      }
      const {
        data
      } = res
      const menuNav = []
      const childrenNav:any[] = []
      //      后端数据, 根级树数组,  根级 PID
      listToTree(data, childrenNav, 0)
      menuNav.push(...childrenNav)
      const routers = generator(menuNav, null)
      routers.forEach((r:any) => {
        router.addRoute(r)
      })
      store.SET_ROUTERS(routers[0].children)
      // 请求带有 redirect 重定向时，登录自动重定向到该地址
      const redirect = decodeURIComponent(from.query.redirect || to.path)
      if (to.path === redirect) {
        // set the replace: true so the navigation will not leave a history record
        next({
          ...to,
          replace: true
        })
      } else {
        // 跳转到目的路由
        next({
          path: redirect
        })
      }
    }).catch((error: any) => {
      if (error.data.code !== 200 || error.data.data.length === 0) {
        setTimeout(function () {
          window.location.href = process.env.VUE_APP_API_BASE_CONSOLE || ''
        }, 5)
      }
    })
    // 获取按钮权限
    getPermissionApi(params).then((res: any) => {
      if (res.code !== 200) return ElMessage.error(res.message)
      store.SET_PERMISSION(res.data)
    })
    getRolesApi(params).then((res: any) => {
      if (res.code !== 200) return ElMessage.error(res.message)
      store.SET_ROLES(res.data)
    })

    // 获取用户信息
    getUserInfoApi().then((res: any) => {
      if (res.code !== 200) return ElMessage.error(res.message)
      store.SET_USERS(res.data)
    })
    //获取当前人员职位
    getUserByTokenApi({}).then((res: any)=>{
      if (res.code !== 200) return ElMessage.error(res.message)
      store.SET_POSITION(res.data)
    })
  } else {
    next()
  }
})

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @returns {*}
 */
export const generator = (routerMap: any, parent: any) => {
  return routerMap.map((item: any) => {
    const {
      title,
      show,
      hideChildren,
      hiddenHeaderContent,
      target,
      icon
    } = item.meta || {}
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path,
      // 路由名称，建议唯一
      name: item.name,
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: constantRouterComponents[item.component] || (() => import(`@/views/${item.component}`)),
      // 是否设置了隐藏菜单
      hidden: false,
      // 是否设置了隐藏菜单
      hideChildrenInMenu: false,
      // 重定向
      redirect: item.redirect,
      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        hiddenHeaderContent: hiddenHeaderContent,
        target: target
      } as any,
      // 是否有子菜单，并递归处理
      children: []
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true
    }
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    if (currentRouter.path.includes('interview')) {
      currentRouter.meta.keepAlive = true
    }
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
export const listToTree = (list: any, tree: any, parentId: any) => {
  list.forEach((item: any) => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: []
      }
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id)

      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到树中
      tree.push(child)
    }
  })
}
