/*
 * @Descripttion: 
 * @version: 
 * @Author: wenboliu
 * @Date: 2024-04-01 19:55:09
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-05-21 10:56:38
 */
import { defineStore } from 'pinia'
export const userDataStore = defineStore('userData', {
  state: () => ({
    /* 所有路由 */
    routersList: <any>[],
    /* 所有权限 */
    permissionList: <any>[],
    /* 当前active导航菜单 */
    currentMenu: '',
    /* 导航菜单是否折叠 */
    isCollapse: false,
    // 角色信息
    rolesList: <any>[],
    // 用户信息
    userList: <any>[],
    // 面包屑
    crumbList: <any>[],
    //职位信息
    positionInfo: {} as any
  }),
  actions: {
    /* 所有路由 */
    SET_ROUTERS (routes: Array<any>) {
      this.routersList = routes || []
    },
    /* 所有权限 */
    SET_PERMISSION (routes: Array<any>) {
      this.permissionList = routes
    },
    /* 当前active导航菜单 */
    SET_CURRENT_MENU (currentMenu: string) {
      this.currentMenu = currentMenu
    },
    /* 导航菜单是否折叠 */
    switchCollapse (isCollapse: boolean) {
      this.isCollapse = isCollapse
    },
    /* 角色信息 */
    SET_ROLES (routes: Array<any>) {
      this.rolesList = routes
    },
    /* 面包屑导航列表 */
    SET_CRUMBLIST (list: Array<any>) {
      this.crumbList = list
    },
    SET_USERS (routes:Array<string>) {
      this.userList = routes
    },
    SET_POSITION (positionInfo: any) {
      this.positionInfo = positionInfo
    },
  },
  getters: {
    getRouters: (state: any) => state.routersList,
    getPermission: (state: any) => state.permissionList,
    getCurrentMenu: (state: any) => state.currentMenu,
    getIsCollapse: (state: any) => state.isCollapse,
    getRolesList: (state: any) => state.rolesList,
    getUserList: (state: any) => state.userList,
    getCrumbList: (state: any) => state.crumbList,
    getPositionInfo : (state: any) => state.positionInfo,
  }
})
