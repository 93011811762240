<!--
 * @Descripttion:
 * @version:
 * @Author: 毛凯悦
 * @Date: 2024-03-31 13:36:56
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-06-04 15:00:22
-->
<template>
  <!-- 公共Home组件 -->
  <div class="wrapper">
    <Top />
    <!-- 侧边栏组件 -->
    <Left />
    <div class="content-box" :class="{'content-collapse':isCollapse}">
      <Tags />
      <div class="content">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" v-if="route.meta.keepAlive" :key="route.fullPath"/>
          </keep-alive>
          <component :is="Component" v-if="!route.meta.keepAlive" :key="route.fullPath"/>
        </router-view>
        <el-backtop target=".content"></el-backtop>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import Top from '@/components/nav/navTop.vue'
import Left from '@/components/nav/navLeft.vue'
import Tags from '@/components/nav/navTags.vue'
import { userDataStore } from '@/store'
export default defineComponent({
  name: 'index',
  setup () {
    const store = userDataStore()
    const isCollapse = computed(() => store.getIsCollapse)
    const screenWidth = ref(0)
    const route = useRoute()
    onMounted(() => {
      window.addEventListener('resize', function () {
        screenWidth.value = document.body.offsetWidth
      })
    })

    watch(screenWidth, (n, o) => {
      if (n <= 1200) {
        store.switchCollapse(true)
      } else {
        store.switchCollapse(false)
      }
    })

    return { isCollapse, route }
  },

  components: { Top, Left , Tags}
})
</script>

<style lang="less" scoped>
</style>
